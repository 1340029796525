import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class RadioInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public title!: string;
    private validationArray: ValidationModel[] = [];
    private radioStyle: LooseObject = {};

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        if (this.props.defaultEditValue !== null) {
            this.$emit('update:value', this.props.defaultEditValue);
            this.props.value = this.props.defaultEditValue;
        }
        this.setInitialObjectValues();
        if (this.props.orientation === 'column') {
            this.radioStyle = {
                display: 'flex !important',
                lineHeight: '30px !important',
                alignItems: 'center',
            };
        }
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.props.value);
    }

    private isValueValid() {
        if (this.props.required && !this.props.value) {
            return false;
        }
        return true;
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit(
                'submitValue',
                this.props.elementId,
                this.props.value,
                this.props.label,
                getFormattedString(this.props, this.props.value),
            );
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
        ];
    }
}
