import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class RadioButtonInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public title!: string;
    private validationArray: ValidationModel[] = [];

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        if (this.props.defaultEditValue !== null) {
            this.$emit('update:value', this.props.defaultEditValue);
            this.props.value = this.props.defaultEditValue;
        }
        this.setInitialObjectValues();
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.props.value);
    }

    private submitValue() {
        EventBus.$emit(
            'submitValue',
            this.props.elementId,
            this.props.value,
            this.props.label,
            getFormattedString(this.props, this.props.value),
        );
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
        ];
    }
}
