import { Component, Vue, Ref } from 'vue-property-decorator';
import SidePanel from '@/views/SidePanel/SidePanel.vue';
import {EventBus} from '@/utilities/eventBus/EventBus';
import SideMenu from '@/components/Menu/Menu.vue';

@Component({
    components: {
        SidePanel,
        SideMenu,
    },
})
export default class FormSection extends Vue {
    @Ref('contentMenu') private contentMenu?: HTMLElement;
    @Ref('contentLeft') private contentLeft!: HTMLElement;
    @Ref('contentRight') private contentRight!: HTMLElement;

    public loginRequired(): boolean {
        return this.$msal.requiresLogin;
    }

    public isAuthenticated(): boolean {
        return this.$msal.isAuthenticated;
    }

    public async signIn() {
        const loc = window.location;
        await this.$msal.signIn(loc.protocol + '//' + loc.host + '/#/');
    }

    protected mounted() {
        EventBus.$on('update-form-panel-height', this.updateFormPanelHeight);
        EventBus.$on('update-side-panel-scroll', this.scrollMessages);
        EventBus.$on('updatedMessageBubble', (messageOffsetTop: number) => this.scrollWindow(messageOffsetTop));
        window.onresize = this.updateFormPanelHeight;
    }

    private scrollMessages() {
        this.contentLeft.scrollTo({top: this.contentLeft.scrollHeight, left: 0, behavior: 'smooth'});
    }

    private scrollWindow(offsetTop: number) {
        const rightPos = this.contentRight.offsetTop;
        const scrollPos = rightPos - offsetTop;
        if (rightPos > 0) {
            window.scrollTo({top: scrollPos, left: 0, behavior: 'smooth'});
        } else {
            window.scrollTo({top: 150, left: 0, behavior: 'smooth'});
        }
    }

    private updateFormPanelHeight() {
        if (this.contentLeft) {
        this.contentLeft.style.height = `${this.contentRight.scrollHeight}px`;
        }
    }
}
