import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import Client from 'getaddress-api';
import CustomGoogleAutocomplete from 'vue-custom-google-autocomplete';
import store from '@/store';
import { Select } from 'ant-design-vue';

@Component
export default class AddressInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    @Ref('addressSelect') private addressSelect!: Select;
    @Ref('googleSelect') private googleSelect!: Select;
    private validationArray: ValidationModel[] = [];
    private inputValue = '';
    private authorized: boolean = false;
    private fetching: boolean = false;
    private searchInput: string = '';
    private searchResults: LooseObject = {};


    protected created() {
        this.createValidationObject();
        this.checkForEditValue();
        this.props.placeholder = 'Addresses will appear here when available';
        this.props.authorized = false;

        this.props.googleOptions = {
            key: this.props.apiKey,
            sessiontoken: store.state.sessionIdentifier,
        };
        if (this.props.countryLimitCode) {
            this.props.googleOptions.components = 'country:' + this.props.countryLimitCode;
        }
        Vue.use(CustomGoogleAutocomplete);


    }


    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }
    private async getAddress(postcode: string) {
        const newPostcode = postcode.replace(' ', '');

        const api = new Client('l2KVjb2l5UeSajWb4mnzMQ35636');

        const findResult = await api.find(newPostcode);

        if (findResult.isSuccess) {
            const success = findResult.toSuccess();
            this.props.success = success;
            let index = 0;
            const formattedArray = Array();
            for (const items of Object.keys(success.addresses.addresses)) {
                if (formattedArray[index] === undefined) {
                    formattedArray[index] = {};
                }
                const regex = /,+/g;
                formattedArray[index].value = items;
                formattedArray[index].label =
                    success.addresses.addresses[Number(items)].formatted_address.toString().replace(regex, ', ');
                index = index + 1;
            }
            this.addressSelect.options = formattedArray;
            this.addressSelect.placeholder = 'Addresses found, please select from the list';
            (document.getElementById('addressSelect') as HTMLInputElement).focus();
        } else {
            const failed = findResult.toFailed();
        }
    }

    private async onChange() {
        this.$emit('update:value', this.props.options);
    }
    private async onSelect() {
        if (this.props.buildingNumber) {
            EventBus.$emit('Updating ' + this.props.buildingNumber,
                this.props.success.addresses.addresses[this.props.value].building_number);
        }
        if (this.props.buildingName) {
            EventBus.$emit('Updating ' + this.props.buildingName,
                this.props.success.addresses.addresses[this.props.value].building_name);
        }
        if (this.props.street) {
            EventBus.$emit('Updating ' + this.props.street,
                this.props.success.addresses.addresses[this.props.value].thoroughfare);
        }
        if (this.props.town) {
            EventBus.$emit('Updating ' + this.props.town,
                this.props.success.addresses.addresses[this.props.value].town_or_city);
        }
        if (this.props.county) {
            EventBus.$emit('Updating ' + this.props.county,
                this.props.success.addresses.addresses[this.props.value].county);
        }
        if (this.props.country) {
            EventBus.$emit('Updating ' + this.props.country,
                this.props.success.addresses.addresses[this.props.value].country);
        }
    }
    private async setAddress(prediction: LooseObject) {

        if (this.props.buildingNumber) {
            EventBus.$emit('Updating ' + this.props.buildingNumber, '');
        }
        if (this.props.buildingName) {
            EventBus.$emit('Updating ' + this.props.buildingName, '');
        }
        if (this.props.street) {
            EventBus.$emit('Updating ' + this.props.street, '');
        }
        if (this.props.town) {
            EventBus.$emit('Updating ' + this.props.town, '');
        }
        if (this.props.county) {
            EventBus.$emit('Updating ' + this.props.county, '');
        }
        if (this.props.country) {
            EventBus.$emit('Updating ' + this.props.country, '');
        }
        if (this.props.postcode) {
            EventBus.$emit('Updating ' + this.props.postcode, '');
        }

        const myHeaders = new Headers();

        myHeaders.append('form', 'ggg');

        myHeaders.append('clientUrl', 'https://dev.demo-web.prinsix.com/test');

        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            proxy: {
                url: 'https://maps.googleapis.com/maps/api/place/details/json',
                method: 'GET',
                contentType: 'application/json',
                query: {
                    place_id: prediction.place_id,
                    key: this.props.apiKey,
                },
            },
        });

        const requestOptions = {
            method: 'POST',
            body: raw,
            contentType: 'application/json',
            headers: myHeaders,
        };
        const response = await fetch(document.location.origin + '/api/ui/?version=2023-03-28', requestOptions);
        const data = await response.json();

        for (const item of Object.keys(data.result.address_components)) {

            if (data.result.address_components[item].types.includes('street_number')) {
                if (this.props.buildingNumber) {
                    EventBus.$emit('Updating ' + this.props.buildingNumber,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('premise')) {
                if (this.props.buildingName) {
                    EventBus.$emit('Updating ' + this.props.buildingName,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('route')) {

                if (this.props.street) {
                    EventBus.$emit('Updating ' + this.props.street,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('locality')
                || data.result.address_components[item].types.includes('postal_town')) {

                if (this.props.town) {
                    EventBus.$emit('Updating ' + this.props.town,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('administrative_area_level_1')) {
                if (this.props.county) {
                    EventBus.$emit('Updating ' + this.props.county,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('country')) {
                if (this.props.country) {
                    EventBus.$emit('Updating ' + this.props.country,
                        data.result.address_components[item].long_name);
                }
            } else if (data.result.address_components[item].types.includes('postal_code')) {
                if (this.props.postcode) {
                    EventBus.$emit('Updating ' + this.props.postcode,
                        data.result.address_components[item].long_name);
                }
            }
        }
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
            return false;
        }
        let valid = true;
        if (this.props.minLength && this.props.maxLength) {
            const { min, max } = { min: this.props.minLength, max: this.props.maxLength };
            valid = this.value.length >= min && this.value.length <= max;
        }
        if (this.props.pattern && valid) {
            const match = this.value.match(new RegExp(this.props.pattern));
            valid = match ? true : false;
        }
        return valid;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.props.elementId, this.value, this.props.label);
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
            {
                min: this.props.minLength,
                max: this.props.maxLength,
                message: this.props.validationMessage || `please keep between ${this.props.minLength} and ${this.props.maxLength} characters`,
                trigger: 'blur',
            },
        ];
        if (this.props.pattern !== undefined && this.props.pattern !== '') {
            this.validationArray.push(
                {
                    pattern: new RegExp(this.props.pattern),
                    message: this.props.validationMessage || `value does not meet the required input pattern`,
                    trigger: 'blur',
                },
            );
        }
    }

    private updateInputValues(inputValue: string) {
        this.inputValue = inputValue;
        this.$emit('update:value', this.inputValue);
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        }
    }
    private isGetAddressIO() {
        if (this.props.provider === 'GetAddressIO API') {
            return true;
        } else {
            return false;
        }
    }

    private isGoogleAddress() {
        if (this.props.provider === 'Google Places API') {
            return true;
        } else {
            return false;
        }
    }

    private async updateSelect(searchInput: string) {
        this.props.googleOptions.input = searchInput;
        this.fetching = true;

        const myHeaders = new Headers();

        myHeaders.append('form', 'ggg');

        myHeaders.append('clientUrl', 'https://dev.demo-web.prinsix.com/test');

        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            proxy: {
                url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
                method: 'GET',
                contentType: 'application/json',
                query: this.props.googleOptions,
            },
        });

        const requestOptions = {
            method: 'POST',
            body: raw,
            contentType: 'application/json',
            headers: myHeaders,
        };
        const response = await fetch(document.location.origin + '/api/ui/?version=2023-03-28', requestOptions);
        const data = await response.json();

        this.searchResults = data.predictions;
        this.fetching = false;
        this.googleSelect.open = true;
        this.props.placeholder = 'Select your address from the list';
    }

    private closeSelect() {
        this.googleSelect.open = false;
    }

    private changeSelect() {
        if (this.googleSelect.open) {
            this.googleSelect.open = false;
        } else {
            this.googleSelect.open = true;
        }
    }

}
