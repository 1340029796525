import { Prop, Component, Vue } from 'vue-property-decorator';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import { EventBus } from '@/utilities/eventBus/EventBus';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class NumberInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private validationArray: ValidationModel[] = [];
    private inputValue = '';
    private formattedPlaceholder: string = getFormattedString(this.props, this.props.placeholder);

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        this.checkForEditValue();
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
            return false;
        }
        if (!this.props.minLength && this.props.maxLength) {
            const { min, max } = { min: this.props.minValue, max: this.props.maxValue };
            return this.value > min && this.value < max;
        }
        return true;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        const formattedString = getFormattedString(this.props, this.value);
        if (this.isValueValid()) {
            if (this.props.decimalsLength > 0) {
                EventBus.$emit(
                    'submitValue',
                    this.props.elementId,
                    parseFloat(this.value),
                    this.props.label,
                    formattedString,
                );
            } else {
                EventBus.$emit(
                    'submitValue',
                    this.props.elementId,
                    parseInt(this.value, 10),
                    this.props.label,
                    formattedString,
                );
            }
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
            { validator: this.checkMinMaxValues, trigger: 'blur' },
        ];
        if (!this.props.decimals) {
            this.props.decimalsLength = 0;
        } else {
            this.props.decimalsLength = this.props.decimals + 1;
        }
        this.props.minLength = getFormattedString(this.props, this.props.minValue).length;
        this.props.maxLength = getFormattedString(this.props, this.props.maxValue).length + this.props.decimalsLength;
    }

    private updateInputValues(inputValue: string) {
        const newValue = inputValue.replace(/[^0-9.]/g, '');
        this.$emit('update:value', newValue);
        newValue === '' ? this.inputValue = '' : this.inputValue = getFormattedString(this.props, newValue);
    }

    private checkMinMaxValues(rule: any, value: string, callback: any) {
        if (this.value < this.props.minValue || this.value > this.props.maxValue) {
            callback(new Error(this.props.validationMessage || `please keep between ${getFormattedString(this.props, this.props.minValue)} and ${getFormattedString(this.props, this.props.maxValue)} `));
        } else {
            callback();
        }
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        }
    }
}
