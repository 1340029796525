import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import VueBase64FileUpload from 'vue-base64-file-upload';

@Component({
    components: { VueBase64FileUpload },
})
export default class FileInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private file: LooseObject = {
        lastModifiedDate: String,
        name: String,
        size: Number,
        type: String,
        data: String,
    };
    private validationArray: ValidationModel[] = [];

    protected created() {
        this.createValidationObject();
        this.file = {};
        EventBus.$on('requestValue', this.submitFile);
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitFile);
    }

    private onFile(file: LooseObject) {
        this.file.lastModifiedDate = file.lastModifiedDate;
        this.file.name = file.name;
        this.file.size = file.size;
        this.file.type = file.type;
    }

    private onLoad(data: string) {
        this.file.data = data;
        this.props.type = this.file.type;
        this.$emit('update:value', data);
    }

    private isValueValid() {
        if (this.props.required && (!this.file.data || this.file.size < 10)) {
            return false;
        }
        if (this.props.fileType === 'Image' && this.props.type && !['image/png', 'image/gif', 'image/jpeg']
        .includes(this.file.type)) {
            return false;
        }
        if (this.props.fileType === 'Document' && this.props.type && !['application/msword', 'application/pdf']
        .includes(this.file.type)) {
            return false;
        }
        if (this.props.fileType === 'Both' && this.props.type && !['image/png', 'image/gif', 'image/jpeg', 'application/msword', 'application/pdf']
        .includes(this.file.type)) {
            return false;
        }
        let valid = true;
        if (this.props.maxSize) {
            valid = this.file.size <= this.props.maxSize * 1024;
        }
        return valid;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitFile() {
        if (this.isValueValid()) {
            EventBus.$emit('submitFile', this.props.elementId, this.file, this.props.fileName);
        }
    }

    private fileTypes() {
        if (this.props.fileType === 'Image') {
            return 'image/png,image/gif,image/jpeg';
        } else if (this.props.fileType === 'Document') {
            return 'application/msword,application/pdf';
        } else if (this.props.fileType === 'Both') {
            return 'application/msword,application/pdf,image/png,image/gif,image/jpeg';
        } else {
            return '.*';
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please select a file to upload',
                trigger: 'blur',
            },
            {
                max: this.props.maxSize * 1024,
                message: this.props.validationMessage || `Please keep file size under ${this.props.maxSize}KB`,
                trigger: 'blur',
            },
            { validator: this.checkType, trigger: 'blur' },
        ];
    }

    private checkType(rule: any, value: string, callback: any) {
        if (this.props.fileType === 'Image' && this.props.type && !['image/png', 'image/gif', 'image/jpeg']
        .includes(this.file.type)) {
            callback(new Error(this.props.validationMessage || 'Please choose an image file type'));
        } else if (this.props.fileType === 'Document' && this.props.type && !['application/msword', 'application/pdf']
        .includes(this.file.type)) {
            callback(new Error(this.props.validationMessage || 'Please choose a document file type'));
        } else if (this.props.fileType === 'Both' && this.props.type && !['image/png', 'image/gif', 'image/jpeg', 'application/msword', 'application/pdf']
        .includes(this.file.type)) {
            callback(new Error(this.props.validationMessage || 'Please choose either a document or image file type'));
        } else {
            callback();
        }
    }
}
