import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class CheckboxInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public title!: string;
    private validationArray: ValidationModel[] = [];

    private indeterminate: boolean = true;
    private checkAll: boolean = false;
    private checkedList: LooseObject[] = [];
    private checkedListLabel: LooseObject[] = [];
    private checkboxStyle: LooseObject = {};

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        if (this.props.defaultEditValue !== null) {
            this.$emit('update:value', this.props.defaultEditValue);
            this.checkedList = this.props.defaultEditValue;
        }
        this.setInitialObjectValues();
        this.props.optionNames = [];
        this.props.optionValues = [];
        this.props.defaultValues = [];

        if (this.props.orientation === 'column') {
            this.checkboxStyle = {
                display: 'inline-flex !important',
                flexDirection: 'column',
                flexWrap: 'wrap',
                lineHeight: '30px',
            };
        }
        for (const index in this.props.options) {
            if (Object.prototype.hasOwnProperty.call(this.props.options, index)) {
                const option = this.props.options[index];
                this.props.optionNames[index] = option?.title;
                this.props.optionValues[index] = option?.value;
                if (option?.default) {
                    this.props.defaultValues[this.props.defaultValues.length] = option?.title;
                }
            }
        }
        if (this.checkedList.length === 0) {
            this.checkedList = this.props.defaultValues;
        } else {
            for (const item in this.checkedList) {
                if (Object.prototype.hasOwnProperty.call(this.checkedList, item)) {
                    const checkedListItem = this.checkedList[item];
                    for (const index in this.props.optionNames) {
                        if (Object.prototype.hasOwnProperty.call(this.props.optionNames, index)) {
                            const optionNameItem = this.props.optionNames[index];
                            if (checkedListItem === this.props.optionValues[index]) {
                                this.checkedList[item] = optionNameItem;
                                break;
                            }
                        }
                    }
                }
            }
        }
            // alert(this.checkedList);
        this.$emit('update:value', this.checkedList);
        }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.checkedList);
    }

    private isValueValid() {
        if (this.props.required && this.checkedList.length === 0) {
            return false;
        }
        return true;
    }

    private submitValue() {
        if (this.isValueValid()) {
            for (const item in this.checkedList) {
                if (Object.prototype.hasOwnProperty.call(this.checkedList, item)) {
                    const checkedListItem = this.checkedList[item];
                    for (const index in this.props.optionNames) {
                        if (Object.prototype.hasOwnProperty.call(this.props.optionNames, index)) {
                            const optionNameItem = this.props.optionNames[index];
                            if (checkedListItem === optionNameItem) {
                                this.checkedListLabel[item] = optionNameItem;
                                this.checkedList[item] = this.props.optionValues[index];
                                break;
                            }
                        }
                    }
                }
            }
            this.props.checkedList = this.checkedList;
            this.$emit('update:value', this.props.checkedList);
            EventBus.$emit(
                'submitValue',
                this.props.elementId,
                this.checkedList.join(','),
                this.props.label,
                this.checkedListLabel.join(','),
            );
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please select at least one option from the list',
                trigger: 'blur',
            },
        ];
    }

    private onChange(checkedList: any) {
        this.indeterminate = !!checkedList.length && checkedList.length < this.props.optionNames.length;
        this.checkAll = checkedList.length === this.props.optionNames.length;
        this.$emit('update:value', this.checkedList);
    }

    private onCheckAllChange(e: any) {
        Object.assign(this, {
            checkedList: e.target.checked ? this.props.optionNames : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        this.$emit('update:value', this.checkedList);
    }
}
